import React from "react";
import { connect } from "react-redux";

class UserHeader extends React.Component {
  render() {
    // const user = this.props.users.find((user) => user.id === this.props.userId);

    const { user } = this.props;

    if (!user) {
      return <div>Unknown User</div>;
    }

    return <div className="header">{user.name}</div>;
  }
}

const mapStatsToProps = (state, ownProps) => {
  return {
    user: state.users.find((user) => user.id === ownProps.userId),
  };
};

export default connect(mapStatsToProps)(UserHeader);
